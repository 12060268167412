<template>
    <div class="table_box">
        <iframe src="http://developer.sentinel.chaozan.cc" class="iframe" scrolling="no" frameborder="no" border="0"></iframe>
    </div>
</template>

<script>
    export default {
        name: "sentinel"
    }
</script>

<style scoped>
    .table_box {
        padding: 15px;
        margin: auto;
        background: #fff;
        height: 90vh;
    }
    .iframe{
        width: 100%;
        height: 100%;
    }
</style>